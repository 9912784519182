<template>
    <div class="base-layout" @click="toggleMenu">
        <Navbar class="navbar-container"/>
        <div class="bg">
            <section>
                <router-view/>
            </section>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/ui/Navbar"
import "@/plugins/echo"

export default {
    components: {Navbar},
    computed: {
        userId() {
            return this.$store.getters.identity.id || null
        },
        userChannel() {
            return this.$echo.private(`users.${this.userId}`)
        },
    },
    mounted() {
        if (this.userId) {
            this.userChannel.notification((notification) => {
                if (notification.owner == 'import' || notification.owner == 'export') {
                    notification.time = this.helper.getTime(new Date().getTime())

                    let allNotification;
                    if (localStorage.getItem('notification') === null) {
                        allNotification = [];
                    } else {
                        allNotification = JSON.parse(localStorage.getItem('notification'))
                    }

                    allNotification.push(notification)
                    let result = JSON.stringify(allNotification)
                    localStorage.setItem('notification', result)
                    this.updateNotification()
                }

                if (notification.owner == 'analytic') {
                    this.$store.dispatch('setNewAnalytic', true)
                }
            })


        }
    },
    methods: {
        updateNotification() {
            this.$store.dispatch('getNotifications')
        },
        toggleMenu(e){
            let navbar = document.querySelector('.navbar-collapse')
            if (window.innerWidth < 992 && !e.target.classList.contains('dropdown-toggle')){
                if(navbar.classList.contains('show')){
                    navbar.classList.remove('show')
                }
            }
        }
    },
    destroyed() {
        this.$echo.leave(
            `users.${this.userId}`
        );
    },
    created() {
        // if (this.role.getRole() === 1){
        //     // this.$router.push({name: 'StatisticsCinema'}).catch(() => {})
        // } else if (this.role.getRole() === 2){
        //     // this.$router.push({name: 'Statistics'}).catch(() => {})
        // } else if (this.role.getRole() === 3){
        //     this.$router.push({name: 'StatisticsDistributors'}).catch(() => {})
        // } else if (this.role.getRole() === 4){
        //     this.$router.push({name: 'StatisticsNetwork'}).catch(() => {})
        // } else if (this.role.getRole() === 5){
        //     this.$router.push({name: 'StatisticsCinema'}).catch(() => {})
        // }
    }
}
</script>

<style lang="scss" scoped>
.base-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .navbar-container {
        flex: 0 0 auto;
    }

    .bg {
        max-height: calc(100vh - 76px);
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    section {
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
</style>
