<template>
    <div class="wrapper" @click="closePopup($event)">
        <div class="notification-container">
            <header class="head">
                <div class="title">
                    {{$t('Notification')}}
                </div>
                <div @click="$emit('changeStatusNotification')" class="btn-toggle">
                    <button type="button"><span class="material-icons">cancel</span></button>
                </div>
            </header>
            <div class="main" :class="{'no-scroll' : !list.length}">
                <div class="empty-list" v-if="!reverseNotification.length">{{$t('The notification list is empty')}}</div>
                <div class="item" v-for="item in reverseNotification">
                    <div class="text">
                        <div class="label-good" v-if="item.status === 'success'">
                            <span class="material-icons label">check_circle</span>
                        </div>
                        <div class="label-bad" v-if="item.status === 'fail'">
                            <span class="material-icons-outlined label">error</span>
                        </div>
                        <div class="text-message">
                            {{ item.message }}
                        </div>
                    </div>
                    <div class="download">
                        <div class="time-message">
                            {{item.time}}
                        </div>
                        <a :href="item.link" target="_blank" v-if="item.link">
                            <span :title="$t('Download')" class="material-icons">download</span>
                        </a>
                    </div>
                </div>
            </div>
            <footer class="bottom">
                <router-link @click.native="$emit('changeStatusNotification')" :to="{name: 'Notifications'}" class="link-to-all">
                    {{$t('To all notifications')}}
                </router-link>
                <button v-if="allNotification.length" type="button" @click="clearNotificationList" class="btn-read">
                    {{$t('Read all')}}
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import "@/plugins/echo"


export default {
    name: "Notification",
    data() {
        return {
            allNotification: [],
        }
    },
    computed: {
        list() {
            return this.$store.getters.getNotificationsList
        },
        reverseNotification(){
            return this.allNotification.reverse()
        }
    },
    watch: {
        list() {
            this.allNotification = this.list
            if (this.list.length) {
                this.$emit('toggleActiveStatus', true)
            } else {
                this.$emit('toggleActiveStatus', false)
            }
        },
    },
    methods: {
        clearNotificationList() {
            localStorage.removeItem("notification")
            this.allNotification = []
            this.$emit('toggleActiveStatus', false)
            this.$emit('changeStatusNotification')
        },
        getList() {
            this.$store.dispatch('getNotifications')
        },
        closePopup(e){
            let target = e.target.className.replace(/ .*/,'')
            if (target === 'wrapper'){
                this.$emit('changeStatusNotification')
            }
        },
    },
    created() {
        this.getList()
        this.allNotification = this.list
    },
}
</script>

<style scoped lang="scss">
.wrapper{
    width: 100vw;
    right: 0;
    height: 100vh;
    position: absolute;
    top: 0;
}
.notification-container {
    position: fixed;
    top: 90px;
    right: 15.6%;
    width: 555px;
    background-color: white;
    box-shadow: 0 10px 20px 0 rgba(202, 217, 243, 0.4);
    @media (max-width: 768px) {
        width: 100vw;
        right: 0;
    }

    .head {
        display: flex;
        justify-content: space-between;
        padding: 17px 15px;

        .title {
            font-size: 16px;
            font-weight: 900;
            color: #141213;
        }

        .btn-toggle {
            display: flex;
            align-items: center;

            button {
                border: none;
                background-color: white;
                display: flex;
                align-items: center;

                span {
                    color: black;
                    font-size: 18px;

                    &:hover {
                        color: $color_blue;
                    }
                }
            }
        }
    }

    .main {
        width: 100%;
        border-top: 1px solid #cad9f3;
        max-height: 225px;
        overflow-y: scroll;

        .empty-list{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-top: 20px;
            padding-left: 14px;
            color: #646E78;
        }
        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 17px;
            padding: 17px 0;
            border-bottom: 1px solid #edf3ff;
        }

        .label-good, .label-bad {
            display: flex;
            align-items: center;
        }

        .text {
            display: flex;
            align-items: center;

            .label {
                font-size: 23px;
                color: #70dc95;
                margin-right: 17px;
                position: relative;
                bottom: 2px;
            }

            .label-bad {
                .label {
                    color: #ff496d;
                }
            }
        }

        .download {
            position: relative;
            top: 3px;
            display: flex;
            min-width: 21%;
            justify-content: space-between;

            .time-message{
                font-size: 14px;
                letter-spacing: 0.35px;
                color: #646e78;
            }
            a{
                color: $color_darkblue;
            }

            span {
                font-size: 20px;
            }
        }
    }
    .no-scroll{
        overflow-y: hidden;
    }

    footer {
        min-height: 60px;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link-to-all {
            font-size: 16px;
            color: $color_lightpink;
        }

        .btn-read {
            font-size: 14px;
            border-radius: 2px;
            width: 115px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: $color_darkblue;
            border: none;

            &:hover {
                box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                color: $color_lightpink;
            }
        }
    }
}
</style>
