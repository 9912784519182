<template>
    <div>
        <Notification
            @toggleActiveStatus="toggleActiveStatus"
            @changeStatusNotification="changeStatusNotification"
            v-if="statusNotification"
            class="notification-block"
        />
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container">
                <router-link class="navbar-brand" :to="linkHome">
                    <img src="../../assets/img/logo_EKK.svg" alt="" width="50" height="50"
                         class="d-inline-block align-text-baseline">
                    <span class="text-title">{{$t('EKK')}}</span>
                </router-link>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarText"
                    aria-controls="navbarText"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarText">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                        <div class="link-container" v-for="link in filterList">
                            <li class="nav-item" v-if="!link.child">
                                <router-link class="nav-link" aria-current="page" :to="link.url" @click.native="toggleDropdown">
                                    {{ link.title }}
                                </router-link>
                            </li>
                            <li class="nav-item dropdown" v-if="link.child">
                                <router-link
                                    class="nav-link dropdown-toggle"
                                    :to="link.url"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {{ link.title }}
                                </router-link>
                                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" ref="dropdownMenu">
                                    <li v-for="item in link.child" @click="toggleDropdown">
                                        <router-link class="dropdown-item" :to="item.url">{{ item.title }}</router-link>
                                    </li>
                                </ul>
                            </li>
                        </div>
                    </ul>
                </div>
                <div class="link-profile">
                    <router-link :to="{name: 'Profile'}">
<!--                        <img src="../../assets/img/person_normal.svg" alt="profile">-->
                        <span class="material-icons person" :class="{'light-profile' : lightProfile}">person</span>
                    </router-link>
                    <div class="notification">
                        <span @click="changeStatusNotification" class="material-icons">notifications</span>
                        <span class="active-items" v-if="activeStatus"></span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import Notification from "./notification/Notification";

export default {
    name: "Navbar",
    components: {
        Notification
    },
    data() {
        return {
            activeStatus: false,
            statusNotification: false,
            linkHome: null,
            roleName: null,
            filterList: [],
            linkList: [
                {
                    title: this.$t('Statistics'),
                    url: { name: 'Statistics' },
                    accessRole: ['All'],
                },
                {
                    title: this.$t('Analytics'),
                    url: {name: 'CinemaAnalytics'},
                    accessRole: ['All'],
                },
                {
                    title: this.$t('Dynamics'),
                    url: {name: 'Dynamics'},
                    accessRole: ['All'],
                },
                {
                    title: this.$t('Reports'),
                    url: { name: 'Reports' },
                    accessRole: ['All'],
                },
                {
                    title: this.$t('Directory'),
                    url: '/catalog',
                    accessRole: ['SuperAdmin'],
                    child: [
                        {title: this.$t('Cinemas'), url: { name: 'CatalogCinemas' }},
                        {title: this.$t('Distributors'), url: {name: 'CatalogDistributors'}},
                        {title: this.$t('Movies'), url: {name : 'Films'}},
                        {title: this.$t('Networks'), url: {name : 'Network'}},
                        {title: this.$t('Companies'), url: {name: 'Companies'}},
                    ]
                },
                {
                    title: this.$t('Admin panel'),
                    url: '/admin',
                    accessRole: ['SuperAdmin', 'NetworkAdministrator', 'CinemaEmployee'],
                    child: [
                        {
                            title: this.$t('Users'),
                            url: {name: 'AdminUsers'},
                            accessRole: ['SuperAdmin'],
                        },
                        {
                            title: this.$t('Documentation'),
                            url: {name: 'Documentation'},
                            accessRole: ['SuperAdmin', 'NetworkAdministrator', 'CinemaEmployee'],
                        },
                    ]
                },
            ],
            lightProfile: false,
        }
    },
    computed: {
        list() {
            return this.$store.getters.getNotificationsList
        },
        link(){
            return this.$route.name
        },

    },
    watch: {
        list() {
            if (this.list?.length){
                this.activeStatus = true
            } else {
                this.activeStatus = false
            }
        },
        link(){
            if (this.link === 'Profile'){
                this.lightProfile = true
            } else {
                this.lightProfile = false
            }
        }
    },
    methods: {
        changeStatusNotification() {
            this.statusNotification = !this.statusNotification
        },
        toggleActiveStatus(status) {
            this.activeStatus = status
        },
        getListNotification(){
            this.$store.dispatch('getNotifications')
        },
        toggleDropdown(){
            this.$refs.dropdownMenu.forEach(el => {
                el.classList.remove('show')
            })
        },
    },
    created() {
        this.getListNotification()

        this.roleName = this.role.getRoleName()

        if (this.role.getRole() === 1){
            this.linkHome = '/admin/users'
        } else if (this.role.getRole() === 2){
            this.linkHome = '/statistics'
        } else if (this.role.getRole() === 3){
            this.linkHome = '/home/distributors'
        } else if (this.role.getRole() === 4){
            this.linkHome = '/home/network'
        } else if (this.role.getRole() === 5){
            this.linkHome = '/home/cinema'
        }
    },
    mounted(){
        this.linkList.forEach(link => {
            link.accessRole.forEach(role => {
                if (role === this.roleName){

                    if (link.child){
                        link.child.forEach(item => {
                            if (item.accessRole){
                                if (!item.accessRole.find(childrenRole => childrenRole === this.roleName)){
                                    let index = link.child.indexOf(item)
                                    link.child.splice(index, 1)
                                }
                            }
                        })
                    }

                    this.filterList.push(link)
                } else if (role === 'All'){
                    this.filterList.push(link)
                }
            })
        })
    }
}
</script>

<style scoped lang="scss">
$colorActiveText: $color_lightblue;

.notification-block {
    //position: absolute;
    //right: 17%;
    z-index: 999;
    //top: 80px;
    @media (max-width: 768px) {
        right: 0;
    }
}

.navbar {
    background-color: #2d5ca6;
    width: 100%;
    z-index: 99;
    box-shadow: 0 2px 4px 1px rgba(12, 0, 50, 0.2);
    .link-container:last-child{
        margin-right: 43px;
    }
    .navbar-collapse{
        @media (max-width: 991px) {
            position: fixed;
            background-color: #0c0032;
            width: 100vw;
            left: 0px;
            top: 75px;
            z-index: 999;
        }
    }
    .dropdown-menu.show{
        @media (max-width: 991px){
            width: 100vw;
            position: relative;
            left: -32px!important;
        }
    }

    .navbar-brand {
        span {
            @media (max-width: 1200px) {
                display: none;
            }
        }
    }
    .text-title{
        text-transform: uppercase;
    }

    .link-profile {
        position: relative;
        display: flex;
        @media (max-width: 1286px) {
            left: -40px;
        }
        @media (max-width: 992px) {
            position: absolute;
            top: 25px;
            right: 95px;
            left: auto;
        }
        @media (max-width: 580px) {
            top: 25px;
            right: 80px;
        }
        .person{
            color: $colorActiveText;
            font-size: 23px;
        }
        .light-profile{
            color: white;
        }
    }

    .nav-item {
        font-family: $ff-primary;
        margin-left: 29px;
        padding: 0;
        position: relative;
        @media (max-width: 1138px) {
            margin-left: 32px;
        }

        .nav-link {
            font-size: 16px;
            color: #eaf2ff;
            font-weight: 500;
        }

        .router-link-active {
            color: $colorActiveText;
        }

        .dropdown-toggle {
            &:after {
                position: absolute;
                top: 40px;
                right: 47%;
                color: white;
                @media (max-width: 992px) {
                    top: 18px;
                    right: auto;
                }
            }
        }

        .dropdown-menu {
            top: 170%;
            left: -40px;
            background-color: rgba(255, 255, 255, .95);

            .router-link-active {
                color: #212529;
                position: relative;
                &:before {
                    display: inline-block;
                    vertical-align: 0;
                    content: "";
                    border-top: .3em solid;
                    border-right: .3em solid transparent;
                    border-bottom: 0;
                    border-left: .3em solid transparent;
                    color: $colorActiveText;
                    transform: rotate(270deg);
                    position: absolute;
                    left: 9px;
                    top: 13px;
                }
                &:hover{
                    background-color: transparent;
                    cursor: default;
                }
            }

            .dropdown-item {
                padding-left: 25px;
                padding-right: 22px;
            }
        }
    }

    .dropdown {
        .show {
            color: $colorActiveText;

            &:after {
                color: $colorActiveText;
            }
        }
    }

}

.notification {
    position: relative;

    span {
        color: $colorActiveText;
        font-size: 21px;
        position: relative;
        cursor: pointer;
        top: 2px;
        margin-left: 20px;
    }

    .active-items {
        display: block;
        width: 7px;
        height: 7px;
        background-color: $colorActiveText;
        border-radius: 50%;
        position: absolute;
        right: 0;
    }
}

.navbar-brand {
    font-size: 14px;
    font-weight: 900;
    color: white;
    display: flex;
    align-items: center;

    img {
        padding-right: 15px;
    }
}

.navbar {
    @media (max-width: 992px) {
        //position: absolute;
        width: 100vw;
    }
}

</style>
